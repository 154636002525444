import React from "react";
import "./styles.scss";
import img from "../../../../Assets/Images/05-img@3x.png";
import checkbox from "../../../../Assets/Images/05-img-01@3x.png";
import checkboxBold from "../../../../Assets/Images/05-img-02@3x.png";

function Operation() {
  return (
    <div id="operation" className="operation">
      <div className="operation-container">
        <div className="operation-container-content">
          <div className="subTit">연혁&운영</div>
          <div className="title">
          연혁
          </div>
          <ul className="detail">
            {data.map((item,index) => (
              <li className="detail-item" key={index}>
                <img src={item.isChecked ? checkboxBold : checkbox} alt="" />
                <span>{item.content}</span>
              </li>
            ))}
          </ul>

        </div>
        <div className="operation-container-img">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Operation;
const data = [
  {
    isChecked: false,
    content: '국내 최초 AI 기반 알약 분류 시스템 개발'
  },
  {
    isChecked: true,
    content: '성애병원 MOU 체결'
  },
  {
    isChecked: false,
    content: '관련 특허 3건 출원중'
  },
  {
    isChecked: true,
    content: '서울산업진흥원 하드웨어 해커톤 수상 및 T-Stars 선정'
  },
  {
    isChecked: false,
    content: '2022년 서울 혁신 챌린지 협약'
  },
  {
    isChecked: true,
    content: '일산병원 필드 테스트 완료'
  },
  {
    isChecked: false,
    content: '2022 G밸리 IR 데모데이 최우수상'
  },
  {
    isChecked: true,
    content: '강릉 아산병원 필드 테스트 완료'
  },
  {
    isChecked: false,
    content: '2023 청년 창업 사관학교 협약'
  },
  {
    isChecked: true,
    content: '2023 서울 혁신 챌린지(결선) 협약'
  },
]









