import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/04-icon-01@3x.png";
import img2 from "../../../../Assets/Images/04-icon-02@3x.png";
import img3 from "../../../../Assets/Images/04-icon-03@3x.png";
import "./styles.scss";
function Technolygy() {
  return (
    <div id="technology" className="technology">
      <div className="technology-container">
        <Title
          title="핵심기술"
          detail={`메디노드는 의료 현장 실무 및 의료기기 개발 경험을 통한 하드웨어 회로 설계, 집적화 센서 개발, \n
           신호처리 알고리즘 구축 등의 기술을 보유하고 있어, IoT 기반의 의료기기 및 개인용 헬스케어 \n
            솔루션을 개발하고 활용할 수 있습니다.`}
        />
        <ul className="list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <div className="img">
                <img src={item.img} alt="" />
              </div>
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Technolygy;

const data = [
  {
    img: img1,
    title: `Algorithms`,
    detail: `메디노드는 인공지능 \n 알고리즘 알약 식별에 특화, \n 분류기 특화 되어 있습니다.`,
  },
  {
    img: img2,
    title: `Product`,
    detail: `검증된 데이터와 \n 자체 제작의 기술력으로 \n 국내 제작, 생산 됩니다.`,
  },
  {
    img: img3,
    title: `Solution`,
    detail: `분류기를 시작으로 알약의 \n 검수, 식별에 특화된 식별기 \n 제품을 출시 예정 입니다.`,
  },
];
