import React from "react";
import { Link } from "react-router-dom";
import facebookIcon from "../../../../Assets/Images/facebook@3x.png";
import instagramkIcon from "../../../../Assets/Images/instagram@3x.png";
import "./styles.scss";
function Contact() {

  const map =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d669.5810802245604!2d126.88643142770384!3d37.476345754347456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61e113971aa5%3A0x881c693831131d37!2s244%20Beotkkot-ro%2C%20Geumcheon%20District%2C%20Seoul%2C%20South%20Korea!5e0!3m2!1sen!2s!4v1718174733246!5m2!1sen!2s";
  return (
    <div id="contact" className="contact">
      <div className="contact-map">
        <iframe
          src={map}
          style={{
            border: 0,
          }}
          loading="lazy"
          title="map"
        ></iframe>
      </div>
      <div className="contact-content">
        <div className="title">Contact us</div>
        <div className="subtit">Medinode</div>
        <div className="address">
          서울특별시 금천구 벚꽃로 244(가산동), 벽산디지털밸리 5차 802호 (08513)
          <br />
          244, Beotkkot-ro, Geumcheon-gu, Seoul, Republic of Korea
        </div>
        <ul className="contact-info">
          {dataContactInfo.map((item, index) => (
            <li key={index} className="item">
              <div className="item-title">{item.tit}</div>
              <div className="item-detail">{item.detail}</div>
            </li>
          ))}
        </ul>

        <ul className="contact-media">
          <li className="instagram">
            <a href="https://www.instagram.com/medinode.co.kr/" target="_blank">
              <img src={instagramkIcon} alt="" />
            </a>
          </li>

          <li className="facebook">
            <a
              href="https://www.facebook.com/profile.php?id=100092355859528"
              target="_blank"
            >
              <img src={facebookIcon} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;

const dataContactInfo = [
  {
    tit: "이메일",
    detail: "info@medinode.co.kr",
  },
  {
    tit: "전화",
    detail: "02-6265-5333",
  },
  {
    tit: "팩스",
    detail: "02-3281-5333",
  },
];
