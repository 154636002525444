import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Table, Tabs, TabsProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import NoticeService from "../../../../Modules/API/Notice.service";
import moment from "moment";
import Title from "../../../Common/Title";
import ModalData from "../../../Common/ModalData";

interface DataType {
  key: string;
  title: string;
  author: string;
  date?: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "No",
    dataIndex: "index",
    key: 1,
    width: 100,
  },
  {
    title: "제목",
    dataIndex: "title",
    key: 2,
  },
  {
    title: "글쓴이",
    dataIndex: "author",
    key: 3,
  },
  {
    title: "작성시간",
    key: 4,
    dataIndex: "date",
  },
];
interface Props {
  open: boolean;
  setOpen: (e: boolean) => void;
}
function Notice({ open, setOpen }: Props) {
  const [notices, setNotices] = useState<any[]>([]);
  const [news, setNews] = useState<any[]>([]);

  const getNotices = async () => {
    const res: any = await NoticeService.getNotices();
    setNotices(
      res.data?.items?.map((item: any, index: number) => {
        return {
          title: item.fields.title,
          author: item.fields.author,
          date: moment(item.fields.date).locale("ko").format("L"),
          index: index + 1,
          detail: item.fields?.detail || "",
          key: index,
        };
      })
    );
  };
  const getNews = async () => {
    const res: any = await NoticeService.getNotices2();
    setNews(
      res.data?.items?.map((item: any, index: number) => {
        return {
          title: item.fields.title,
          author: item.fields.author,
          date: moment(item.fields.date).locale("ko").format("L"),
          index: index + 1,
          detail: item.fields?.detail || "",
          key: index,
        };
      })
    );
  };

  useEffect(() => {
    getNotices();
    getNews();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "공시정보",
      children: (
        <Table
          rowKey={(record: any) => record.key}
          expandable={{
            expandedRowRender: (record: any) => <ModalData item={record} />,
            expandRowByClick: true,
            expandIcon: () => <div />,
          }}
          columns={columns}
          dataSource={notices}
          scroll={{ x: 600 }}
          pagination={false}
        />
      ),
    },
    {
      key: "2",
      label: "보도자료",
      children: (
        <Table
          rowKey={(record: any) => record.key}
          expandable={{
            expandedRowRender: (record: any) => <ModalData item={record} />,
            expandRowByClick: true,
            expandIcon: () => <div />,
          }}
          columns={columns}
          dataSource={news}
          scroll={{ x: 600 }}
          pagination={false}
        />
      ),
    },
  ];

  return (
    <div className='notice' id='notice'>
      <Tabs defaultActiveKey='1' items={items} />
    </div>
  );
}

export default Notice;
