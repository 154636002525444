import React, { useState } from "react";
import About from "./About";
import Application from "./Application";
import Contact from "./Contact";
import HeroSection from "./HeroSection";
import Intro from "./Intro";
import Invention from "./Invention";
import Mission from "./Mission";
import Operation from "./Operation";
import Technology from "./Technology";
import { Button, Modal, Table } from "antd";
import Notice from "./Notice";
function Home() {
  const [open, setOpen] = useState(true);


  return (
    <main>
      {/* <Modal
        title=""
        centered
        footer={false}
        open={open}
        width={800}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            textAlign: "center",
            padding: "0 20px",
          }}
          className="title"
        >
          제3자배정 유상증자 신주발행 공고
        </div>
        <div
          style={{
            // fontWeight: "bold",
            fontSize: 16,
            textAlign: "center",
            padding: "20px 0px",
          }}
          className="desc"
        >
          주식회사 메디노드는 2024년 1월 8일 개최한 임시주주총회에서 제3자 배정
          방식의 유상증자 를 통한 신주발행에 관하여 아래와 같이 결의하였기에
          상법 제416조에 의거 이를 공고합니다.
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            textAlign: "center",
            padding: "20px 0px",
          }}
          className="desc"
        >
          – 아 래 –
        </div>
        <p style={{ fontSize: 16, paddingTop: 10 }}>
          1. 신주식의 종류와 수 : 상환전환우선주식 4,168주 <br />
          2. 신주의 발행가액과 납입기일 <br />
          <p style={{ paddingLeft: 8 }}>
            - 신주의 발행가액 : 금 23,997원(액면가 1,000원)
          </p>
          <p style={{ paddingLeft: 8 }}>- 납입기일 : 2024년 1월 22일</p>
          3. 신주배정명세 : <br />
          <p style={{ paddingLeft: 8 }}>
            1) 엔슬 13호 벤처투자조합 상환전환우선주식 2,084주
          </p>
          <p style={{ paddingLeft: 8 }}>
            {" "}
            2) 엔슬 15호 개인투자조합 상환전환우선주식 2,084주
          </p>
          4. 신주발행의 방법 : 당 회사 정관 제10조의 2항에 의거 제3자 인수 배정
        </p>

        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            textAlign: "center",
            padding: "20px 0px",
          }}
          className="desc"
        >
          – 아 래 –
        </div>
        <p style={{ fontSize: 16, paddingTop: 10 }}>
          1. 신주식의 종류와 수 : 상환전환우선주식 4,167주 <br />
          2. 신주의 발행가액과 납입기일 <br />
          <p style={{ paddingLeft: 8 }}>
            - 신주의 발행가액 : 금 23,997원(액면가 1,000원)
          </p>
          <p style={{ paddingLeft: 8 }}>- 납입기일 : 2024년 1월 23일</p>
          3. 신주배정명세 : <br />
          <p style={{ paddingLeft: 8 }}>
            1) 신용보증기금 상환전환우선주식 4,167주
          </p>
          4. 신주발행의 방법 : 당 회사 정관 제10조의 2항에 의거 제3자 인수 배정
        </p>

        <div
          className=""
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 30,
            fontSize: 16,
          }}
        >
          <p style={{ textAlign: "center" }}>2024년 1월 8일</p>
          <p>서울특별시 금천구 가산디지털2로 108, 715호(가산동)</p>
          <p>주식회사 메디노드</p>
          <p>사내이사 황 선 일 (법인)</p>
        </div>
      </Modal> */}

      <HeroSection />
      <About />
      <Mission />
      <Technology />
      <Operation />
      <Invention />
      <Intro />
      <Application />
      <Notice open={open} setOpen={setOpen} />
      <Contact />
    </main>
  );
}

export default Home;
