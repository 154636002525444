import React, { useState } from "react";
import logo from "../../../Assets/Images/logo@3x.png";
import logoBlack from "../../../Assets/Images/logo-b@3x.png";
import * as Scroll from "react-scroll";
import "./header.scss";

const Header: React.FC = () => {
  const Link = Scroll.Link;
  const [openMenu, setOpenMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <>
      <header
        style={{
          backgroundColor: navbar ? "#FBFBFB" : "transparent",
          boxShadow: !navbar ? "none" : undefined,
          color: navbar ? "#333333" : "#fff",
        }}
        className='header'
      >
        <div className='header_menu'>
          <div className='header_title'>
            <a href='/' className='logo'>
              {!navbar ? (
                <img src={logo} alt='logo' />
              ) : (
                <img src={logoBlack} alt='logo' />
              )}
            </a>
          </div>
          {/* //header menu  */}
          <ul className='list_menu'>
            {dataRouter.map((item, index) => (
              <li key={index} className='menu_items'>
                <Link offset={-75} smooth={true} to={item.router}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div
          style={{ color: !navbar ? "#fff" : "#222" }}
          onClick={() => setOpenMenu(!openMenu)}
          className='header_mobile_icon'
        >
          {iconMobile}
        </div>
      </header>

      <aside
        onClick={() => setOpenMenu(!openMenu)}
        className={`header-mobile ${openMenu ? "openMenu" : ""}`}
      >
        <div className='icon-close'>
          <span>X</span>
        </div>
        <div className='menu-mobile-items'>
          <ul className='list_menu'>
            {dataRouter.map((item, index) => (
              <li key={index} className='menu_items'>
                <Link
                  onClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                  offset={-70}
                  smooth={true}
                  to={item.router}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Header;

const dataRouter = [
  {
    name: "회사소개",
    router: "about",
  },
  {
    name: "핵심기술",
    router: "technology",
  },
  {
    name: "연혁",
    router: "operation",
  },
  {
    name: "보유기술 소개",
    router: "invention",
  },
  {
    name: "통합 약제 솔루션",
    router: "intro",
  },
  {
    name: "게시판",
    router: "notice",
  },
  {
    name: "Contact us",
    router: "contact",
  },
];

const iconMobile = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='currentColor'
    className='w-6 h-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5'
    />
  </svg>
);
