import React from 'react'
import "./styles.scss";
import img from "../../../../Assets/Images/03-img@3x.png"
import Title from '../../../Common/Title';
import Button from '../../../Common/Button';
function Mission() {
  return (
    <div className='mission'>
        <div className="mission-img">
            <img src={img} alt="" />
        </div>
        <div className="mission-content">
            <Title 
            title={`인공지능이 \n 세상을 스마트하게 만든다. `} 
            detail={`메디노드는 인공지능을 활용한 분류의 혁신을 추구합니다.`}
            fsDetail={24}
            textWhite
            titleExtraBold
            />
        </div>
    </div>
  )
}

export default Mission