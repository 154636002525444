import React from 'react'
import img from "../../../../Assets/Images/09-img@3x.png"
import "./styles.scss"
function Application() {
  return (
    <div className='application'>
        <div className="title">Application</div>
        <img src={img} alt="" />
    </div>
  )
}

export default Application