import React from 'react'
import Title from '../../../Common/Title';
import img1 from "../../../../Assets/Images/07-img-01@3x.png"
import img2 from "../../../../Assets/Images/07-img-02@3x.png"
import img3 from "../../../../Assets/Images/07-img-03@3x.png"
import img4 from "../../../../Assets/Images/07-img-04@3x.png"
import "./styles.scss";
function Invention() {
  return (
    <div id="invention" className='invention'>
        <Title 
        title='보유기술 소개'
        detail={`메디노드는 다양한 특허와 특허 진행 중인 내용을 가지고 있습니다. 약품의 재질 판별, 개수 판별,\n
        처리 및 알약 뒤집힘, 렌즈 컨디션 추적 등의 기능을 수행할 수 있습니다.`}
        />

    <ul className="list">
        {data.map((item,index) => (
            <li key={index}>
                <img src={item.img} alt="" />
                <div className="title">{item.title}</div>
            </li>
        ))}
    </ul>
    </div>
  )
}

export default Invention

const data = [
    {
        img: img1,
        title: '알약 식별 특화 알고리즘'
    },
    {
        img: img2,
        title: 'CNN / Open CV / TensorFlow'
    },
    {
        img: img3,
        title: '이상상태 감지'
    },
]