import React from "react";
import img from "../../../Assets/Images/logo-copy@3x.png";
import "./styles.scss";
import { Link } from "react-router-dom";
import Dropdown from "../Dropdown";
import { HiChevronDown } from "react-icons/hi";
function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="logo">
          <img src={img} alt="" />
        </div>
        <div className="content">
          <ul className="content-menu">
            {data.map((item, index) => (
              <li className="content-menu-item" key={index}>
                <Link to={item.router}>{item.tit}</Link>
              </li>
            ))}
          </ul>

          <div className="content-address">
            <div
              className=""
              style={{ display: "flex", flexDirection: "row",gap: 4 }}
            >
              <p>주소</p>
              <p style={{flex: 1}}>
                서울특별시 금천구 벚꽃로 244(가산동), 벽산디지털밸리 5차 802호
                <br />
                (08513) 244, Beotkkot-ro, Geumcheon-gu, Seoul, Republic of Korea
              </p>
            </div>
            <ul className="content-address-list">
              {dataAddress.map((item, index) => (
                <li className="list-item" key={index}>
                  <div className="list-item-tit">{item.tit}</div>
                  <div className="list-item-detail">{item.detail}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="content-copyright">
            <p>Copyright © Medinode All rights reserved.</p>
          </div>
        </div>

        <div
          className="content-download"
          onClick={() => {
            alert("준비중입니다.");
          }}
        >
          <p>회사소개서</p>
          <div className="icon">
            <HiChevronDown size={22} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const data = [
  {
    tit: "회사소개",
    router: "/",
  },
  {
    tit: "개인정보처리방침",
    router: "/",
  },
  {
    tit: "이용약관",
    router: "/",
  },
  {
    tit: "사업자정보확인",
    router: "/",
  },
];

const dataAddress = [
  {
    tit: "이메일",
    detail: "info@medinode.co.kr",
  },
  {
    tit: "전화",
    detail: "02-6265-5333",
  },
  {
    tit: "팩스",
    detail: "02-3281-5333",
  },
];

const dataDropdown = [
  { id: 0, label: "회사소개서" },
  { id: 1, label: "회사소개서" },
  { id: 2, label: "회사소개서" },
  { id: 3, label: "회사소개서" },
];
