import Router from "./Routers/Router";


function App() {
  return (
    <div className="App">
       <Router />
    </div>
  );
}

export default App;
