import React from 'react'
import "./styles.scss";
import img from "../../../../Assets/Images/08-img@3x.png"
import Title from '../../../Common/Title';
function Intro() {
  return (
    <div id='intro' className='intro'>
      <div className="intro-container">
        <Title title='MediFarm
통합 약제 솔루션'
        detail={`MediFarm은 메디노드의 통합 약제 솔루션 브랜드 입니다.\n
        2023년 출시한 알약 분류기를 시작으로 알약 식별기(2023년), 약품 검수기(2024년)를 출시할 예정입니다.\n
        메디노드의 분류 알고리즘과 약제정보를 기반으로 병원, 약국, 일반까지 모든 곳에서 사용이 가능한 통합 약제 솔루션을 제공합니다. `}
        />
        <div className="intro-container-img">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Intro