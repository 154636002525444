/* eslint-disable jsx-a11y/iframe-has-title */
import img1 from "../../../../Assets/Images/01-img@3x.png";
import img2 from "../../../../Assets/Images/01-img-2@3x.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";

import "./styles.scss";

function HeroSection() {
  return (
    <div className="hero-section">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        loop
        speed={800}
        autoplay
      >
        <SwiperSlide>
          <div className="first-slide">
            <img src={img1} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="first-slide">
            <img src={img2} alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default HeroSection;
