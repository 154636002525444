import React from "react";
import "./styles.scss";
import img from "../../../../Assets/Images/02-img@3x.png";
import logo from "../../../../Assets/Images/02-symbols@3x.png";
function About() {
  return (
    <div id="about" className="about">
      <div className="about-content">
        <div className="subTit">회사소개</div>
        <div className="title">
          {/* <img src={logo} alt="" /> */}
          메디노드는 통합 약제 솔루션 <br />
          메디팜을 구축하여 약제 솔루션의 <br />
          미래를 혁신하고 있습니다.
        </div>
        <div className="detail">
          
          메디노드는 수년간 연구, 개발, 마케팅 경험을 바탕으로 모든 준비를
          마치고 2020년 <br />
          출범하였습니다. 첨단 이미지 센싱 기술부터 딥러닝 소프트웨어 개발,
          하드웨어 개발 <br />
          및 마케팅까지 각 분야의 전문가 네트워크를 통해 창조적인 아이디어로
          미래를 <br />
          준비하는 기업으로 성장하고자 첫걸음을 내디뎠습니다.
        </div>
      </div>
      <div className="about-img">
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default About;
